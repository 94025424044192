define("discourse/plugins/discourse-ai/discourse/connectors/post-text-buttons/ai-post-helper-trigger", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/virtual-element-from-text-range", "truth-helpers/helpers/eq", "discourse/plugins/discourse-ai/discourse/components/ai-post-helper-menu", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _virtualElementFromTextRange, _eq, _aiPostHelperMenu, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _AiPostHelperTrigger;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AiPostHelperTrigger = _exports.default = (_class = (_AiPostHelperTrigger = class AiPostHelperTrigger extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "menu", _descriptor2, this);
      _initializerDefineProperty(this, "menuState", _descriptor3, this);
      _initializerDefineProperty(this, "showMainButtons", _descriptor4, this);
      _initializerDefineProperty(this, "showAiButtons", _descriptor5, this);
      _initializerDefineProperty(this, "originalPostHTML", _descriptor6, this);
      _initializerDefineProperty(this, "postHighlighted", _descriptor7, this);
      _initializerDefineProperty(this, "currentMenu", _descriptor8, this);
      _defineProperty(this, "MENU_STATES", {
        triggers: "TRIGGERS",
        options: "OPTIONS"
      });
    }
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showPostAIHelper)(outletArgs1, helper1);
    }
    highlightSelectedText() {
      const postId1 = this.args.outletArgs.data.quoteState.postId;
      const postElement1 = document.querySelector(`article[data-post-id='${postId1}'] .cooked`);
      if (!postElement1) {
        return;
      }
      this.originalPostHTML = postElement1.innerHTML;
      this.selectedText = this.args.outletArgs.data.quoteState.buffer;
      const selection1 = window.getSelection();
      if (!selection1.rangeCount) {
        return;
      }
      const range1 = selection1.getRangeAt(0);
      // Split start/end text nodes at their range boundary
      if (range1.startContainer.nodeType === Node.TEXT_NODE && range1.startOffset > 0) {
        const newStartNode1 = range1.startContainer.splitText(range1.startOffset);
        range1.setStart(newStartNode1, 0);
      }
      if (range1.endContainer.nodeType === Node.TEXT_NODE && range1.endOffset < range1.endContainer.length) {
        range1.endContainer.splitText(range1.endOffset);
      }
      // Create a Walker to traverse text nodes within range
      const walker1 = document.createTreeWalker(range1.commonAncestorContainer, NodeFilter.SHOW_TEXT, {
        acceptNode: node1 => range1.intersectsNode(node1) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
      });
      const textNodes1 = [];
      if (walker1.currentNode?.nodeType === Node.TEXT_NODE) {
        textNodes1.push(walker1.currentNode);
      } else {
        while (walker1.nextNode()) {
          textNodes1.push(walker1.currentNode);
        }
      }
      for (let textNode1 of textNodes1) {
        const highlight1 = document.createElement("span");
        highlight1.classList.add("ai-helper-highlighted-selection");
        // Replace textNode with highlighted clone
        const clone1 = textNode1.cloneNode(true);
        highlight1.appendChild(clone1);
        textNode1.parentNode.replaceChild(highlight1, textNode1);
      }
      selection1.removeAllRanges();
      this.postHighlighted = true;
    }
    removeHighlightedText() {
      if (!this.postHighlighted) {
        return;
      }
      const postId1 = this.args.outletArgs.data.quoteState.postId;
      const postElement1 = document.querySelector(`article[data-post-id='${postId1}'] .cooked`);
      if (!postElement1) {
        return;
      }
      postElement1.innerHTML = this.originalPostHTML;
      this.postHighlighted = false;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.removeHighlightedText();
    }
    async showAiPostHelperMenu() {
      this.highlightSelectedText();
      if (this.site.mobileView) {
        this.currentMenu.close();
        await this.menu.show((0, _virtualElementFromTextRange.default)(), {
          identifier: "ai-post-helper-menu",
          component: _aiPostHelperMenu.default,
          inline: true,
          placement: this.shouldRenderUnder ? "bottom-start" : "top-start",
          fallbackPlacements: this.shouldRenderUnder ? ["bottom-end", "top-start"] : ["bottom-start"],
          trapTab: false,
          closeOnScroll: false,
          modalForMobile: true,
          data: this.menuData
        });
      }
      this.showMainButtons = false;
      this.menuState = this.MENU_STATES.options;
    }
    get menuData() {
      // Streamline of data model to be passed to the component when
      // instantiated as a DMenu or a simple component in the template
      return {
        ...this.args.outletArgs.data,
        quoteState: {
          buffer: this.args.outletArgs.data.quoteState.buffer,
          opts: this.args.outletArgs.data.quoteState.opts,
          postId: this.args.outletArgs.data.quoteState.postId
        },
        post: this.args.outletArgs.post,
        selectedText: this.selectedText
      };
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.showMainButtons}}
        {{yield}}
      {{/if}}
  
      {{#if this.showAiButtons}}
        <div class="ai-post-helper">
          {{#if (eq this.menuState this.MENU_STATES.triggers)}}
            <DButton
              @icon="discourse-sparkles"
              @title="discourse_ai.ai_helper.post_options_menu.title"
              @label="discourse_ai.ai_helper.post_options_menu.trigger"
              @action={{this.showAiPostHelperMenu}}
              class="btn-flat ai-post-helper__trigger"
            />
  
          {{else if (eq this.menuState this.MENU_STATES.options)}}
            <AiPostHelperMenu @data={{this.menuData}} />
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "NUAbK/gN",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showMainButtons\"]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showAiButtons\"]],[[[1,\"      \"],[10,0],[14,0,\"ai-post-helper\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"menuState\"]],[30,0,[\"MENU_STATES\",\"triggers\"]]],null],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-flat ai-post-helper__trigger\"]],[[\"@icon\",\"@title\",\"@label\",\"@action\"],[\"discourse-sparkles\",\"discourse_ai.ai_helper.post_options_menu.title\",\"discourse_ai.ai_helper.post_options_menu.trigger\",[30,0,[\"showAiPostHelperMenu\"]]]],null],[1,\"\\n\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"menuState\"]],[30,0,[\"MENU_STATES\",\"options\"]]],null],[[[1,\"          \"],[8,[32,2],null,[[\"@data\"],[[30,0,[\"menuData\"]]]],null],[1,\"\\n        \"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/post-text-buttons/ai-post-helper-trigger.js",
    "scope": () => [_eq.default, _dButton.default, _aiPostHelperMenu.default],
    "isStrictMode": true
  }), _AiPostHelperTrigger), _AiPostHelperTrigger), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "menu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "menuState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.MENU_STATES.triggers;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showMainButtons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showAiButtons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "originalPostHTML", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "postHighlighted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentMenu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.menu.getByIdentifier("post-text-selection-toolbar");
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showAiPostHelperMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showAiPostHelperMenu"), _class.prototype)), _class);
});