define("discourse/plugins/discourse-ai/discourse/components/modal/chat-modal-channel-summary", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/conditional-loading-section", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _conditionalLoadingSection, _dModal, _dModalCancel, _ajax, _ajaxError, _i18n, _discourseI18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ChatModalChannelSummary;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatModalChannelSummary = _exports.default = (_class = (_ChatModalChannelSummary = class ChatModalChannelSummary extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "sinceHours", _descriptor2, this);
      _initializerDefineProperty(this, "loading", _descriptor3, this);
      _initializerDefineProperty(this, "summary", _descriptor4, this);
      _defineProperty(this, "availableSummaries", {});
      _defineProperty(this, "sinceOptions", [1, 3, 6, 12, 24, 72, 168].map(hours1 => {
        return {
          name: _discourseI18n.default.t("discourse_ai.summarization.chat.since", {
            count: hours1
          }),
          value: hours1
        };
      }));
    }
    get channelId() {
      return this.args.model.channelId;
    }
    summarize(since1) {
      this.sinceHours = since1;
      this.loading = true;
      if (this.availableSummaries[since1]) {
        this.summary = this.availableSummaries[since1];
        this.loading = false;
        return;
      }
      return (0, _ajax.ajax)(`/discourse-ai/summarization/channels/${this.channelId}.json`, {
        type: "GET",
        data: {
          since: since1
        }
      }).then(data1 => {
        this.availableSummaries[this.sinceHours] = data1.summary;
        this.summary = this.availableSummaries[this.sinceHours];
      }).catch(_ajaxError.popupAjaxError).finally(() => this.loading = false);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        class="chat-modal-channel-summary"
        @title={{i18n "discourse_ai.summarization.chat.title"}}
      >
        <:body>
          <span>{{i18n "discourse_ai.summarization.chat.description"}}</span>
          <ComboBox
            @value={{this.sinceHours}}
            @content={{this.sinceOptions}}
            @onChange={{this.summarize}}
            @valueProperty="value"
            class="summarization-since"
          />
          <ConditionalLoadingSection @isLoading={{this.loading}}>
            <p class="summary-area">{{this.summary}}</p>
          </ConditionalLoadingSection>
        </:body>
        <:footer>
          <DModalCancel @close={{@closeModal}} />
        </:footer>
      </DModal>
    
  */
  {
    "id": "ye9ZWC9O",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-channel-summary\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[32,1],[\"discourse_ai.summarization.chat.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[\"discourse_ai.summarization.chat.description\"],null]],[13],[1,\"\\n        \"],[8,[32,2],[[24,0,\"summarization-since\"]],[[\"@value\",\"@content\",\"@onChange\",\"@valueProperty\"],[[30,0,[\"sinceHours\"]],[30,0,[\"sinceOptions\"]],[30,0,[\"summarize\"]],\"value\"]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@isLoading\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,2],[14,0,\"summary-area\"],[12],[1,[30,0,[\"summary\"]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/chat-modal-channel-summary.js",
    "scope": () => [_dModal.default, _i18n.default, _comboBox.default, _conditionalLoadingSection.default, _dModalCancel.default],
    "isStrictMode": true
  }), _ChatModalChannelSummary), _ChatModalChannelSummary), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sinceHours", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "summary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "summarize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "summarize"), _class.prototype)), _class);
});