define("discourse/plugins/discourse-ai/discourse/lib/ai-topic-summary", ["exports", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/formatter", "discourse/lib/text"], function (_exports, _tracking, _ajax, _formatter, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AiTopicSummary = _exports.default = (_class = class AiTopicSummary {
    constructor() {
      _initializerDefineProperty(this, "text", _descriptor, this);
      _initializerDefineProperty(this, "summarizedOn", _descriptor2, this);
      _initializerDefineProperty(this, "summarizedBy", _descriptor3, this);
      _initializerDefineProperty(this, "newPostsSinceSummary", _descriptor4, this);
      _initializerDefineProperty(this, "outdated", _descriptor5, this);
      _initializerDefineProperty(this, "canRegenerate", _descriptor6, this);
      _initializerDefineProperty(this, "regenerated", _descriptor7, this);
      _initializerDefineProperty(this, "showSummaryBox", _descriptor8, this);
      _initializerDefineProperty(this, "canCollapseSummary", _descriptor9, this);
      _initializerDefineProperty(this, "loadingSummary", _descriptor10, this);
    }
    processUpdate(update) {
      const topicSummary = update.ai_topic_summary;
      return (0, _text.cook)(topicSummary.summarized_text).then(cooked => {
        this.text = cooked;
        this.loading = false;
      }).then(() => {
        if (update.done) {
          this.summarizedOn = (0, _formatter.shortDateNoYear)(topicSummary.summarized_on);
          this.summarizedBy = topicSummary.algorithm;
          this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
          this.outdated = topicSummary.outdated;
          this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
          this.canRegenerate = topicSummary.outdated && topicSummary.can_regenerate;
        }
      });
    }
    collapse() {
      this.showSummaryBox = false;
      this.canCollapseSummary = false;
    }
    generateSummary(currentUser, topicId) {
      this.showSummaryBox = true;
      if (this.text && !this.canRegenerate) {
        this.canCollapseSummary = false;
        return;
      }
      let fetchURL = `/discourse-ai/summarization/t/${topicId}?`;
      if (currentUser) {
        fetchURL += `stream=true`;
        if (this.canRegenerate) {
          fetchURL += "&skip_age_check=true";
        }
      }
      this.loading = true;
      return (0, _ajax.ajax)(fetchURL).then(data => {
        if (!currentUser) {
          data.done = true;
          this.processUpdate(data);
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "text", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "summarizedOn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "summarizedBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newPostsSinceSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "outdated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canRegenerate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "regenerated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showSummaryBox", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canCollapseSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loadingSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
});