define("discourse/plugins/discourse-ai/discourse/components/ai-llm-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/back-button", "discourse/components/d-button", "discourse-i18n", "select-kit/components/combo-box", "discourse/plugins/discourse-ai/discourse/components/ai-llm-editor-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _backButton, _dButton, _discourseI18n, _comboBox, _aiLlmEditorForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AiLlmEditor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AiLlmEditor = _exports.default = (_class = (_AiLlmEditor = class AiLlmEditor extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "presetConfigured", _descriptor, this);
      _defineProperty(this, "presetId", "none");
    }
    get showPresets() {
      return this.args.model.isNew && !this.presetConfigured && !this.args.model.url;
    }
    get preConfiguredLlms() {
      let options1 = [{
        id: "none",
        name: _discourseI18n.default.t(`discourse_ai.llms.preconfigured.none`)
      }];
      this.args.llms.resultSetMeta.presets.forEach(llm1 => {
        if (llm1.models) {
          llm1.models.forEach(model1 => {
            options1.push({
              id: `${llm1.id}-${model1.name}`,
              name: model1.display_name
            });
          });
        }
      });
      return options1;
    }
    configurePreset() {
      this.presetConfigured = true;
      let [id1, model1] = this.presetId.split(/-(.*)/);
      if (id1 === "none") {
        return;
      }
      const info1 = this.args.llms.resultSetMeta.presets.findBy("id", id1);
      const modelInfo1 = info1.models.findBy("name", model1);
      this.args.model.setProperties({
        max_prompt_tokens: modelInfo1.tokens,
        tokenizer: info1.tokenizer,
        url: modelInfo1.endpoint || info1.endpoint,
        display_name: modelInfo1.display_name,
        name: modelInfo1.name,
        provider: info1.provider
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <BackButton
        @route="adminPlugins.show.discourse-ai-llms"
        @label="discourse_ai.llms.back"
      />
      {{#if this.showPresets}}
        <form class="form-horizontal ai-llm-editor">
          <div class="control-group">
            <label>{{I18n.t "discourse_ai.llms.preconfigured_llms"}}</label>
            <ComboBox
              @value={{this.presetId}}
              @content={{this.preConfiguredLlms}}
              class="ai-llm-editor__presets"
            />
          </div>
  
          <div class="control-group ai-llm-editor__action_panel">
            <DButton class="ai-llm-editor__next" @action={{this.configurePreset}}>
              {{I18n.t "discourse_ai.llms.next.title"}}
            </DButton>
          </div>
        </form>
      {{else}}
        <AiLlmEditorForm @model={{@model}} @llms={{@llms}} />
      {{/if}}
    
  */
  {
    "id": "r6uN6b4m",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.show.discourse-ai-llms\",\"discourse_ai.llms.back\"]],null],[1,\"\\n\"],[41,[30,0,[\"showPresets\"]],[[[1,\"      \"],[10,\"form\"],[14,0,\"form-horizontal ai-llm-editor\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.llms.preconfigured_llms\"],null]],[13],[1,\"\\n          \"],[8,[32,2],[[24,0,\"ai-llm-editor__presets\"]],[[\"@value\",\"@content\"],[[30,0,[\"presetId\"]],[30,0,[\"preConfiguredLlms\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"control-group ai-llm-editor__action_panel\"],[12],[1,\"\\n          \"],[8,[32,3],[[24,0,\"ai-llm-editor__next\"]],[[\"@action\"],[[30,0,[\"configurePreset\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.llms.next.title\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,4],null,[[\"@model\",\"@llms\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@model\",\"@llms\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-llm-editor.js",
    "scope": () => [_backButton.default, _discourseI18n.default, _comboBox.default, _dButton.default, _aiLlmEditorForm.default],
    "isStrictMode": true
  }), _AiLlmEditor), _AiLlmEditor), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "presetConfigured", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "configurePreset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "configurePreset"), _class.prototype)), _class);
});